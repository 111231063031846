<template>
  <div>
    <h3 v-t="'productDetails.legal'" />
    <v-row>
      <v-col
        cols="12"
        md="7"
      >
        <span
          v-for="document in generate"
          :key="document"
        >
          <v-icon>{{ downloadIcon }}</v-icon>
          <a
            href="#"
            data-cy="dpa-link"
            @click="onClickDownloadLink(document)"
          >
            <span v-t="'productDetails.generate'" />
            {{ document }}
            <span v-t="'productDetails.forThisContract'" />
          </a>
          <br>
        </span>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <span
          v-for="document in download"
          :key="document"
        >
          <v-icon>{{ downloadIcon }}</v-icon>
          <a
            href="#"
            data-cy="signed-agreement-link"
            @click="onClickDownloadLink(document)"
          >
            <span v-t="'productDetails.download'" />
            {{ document }}
            <span v-t="'productDetails.forThisContract'" />
          </a>
          <br>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  DOWNLOAD_DOCUMENT_ICON_OUTLINED,
  GENERATE_DOCUMENT_ICON
} from '../../../../../constants/app'

export default {
  data: () => {
    return {
      generate: ['DPA'],
      download: ['Signed agreement'],
      generateIcon: GENERATE_DOCUMENT_ICON,
      downloadIcon: DOWNLOAD_DOCUMENT_ICON_OUTLINED
    }
  },
  methods: {
    ...mapActions('productDetails', { downloadFile: 'download' }),
    onClickDownloadLink: function (documentName) {
      this.downloadFile({
        clientId: this.$route.params.clientId,
        contractId: this.$route.params.contractId,
        documentName
      })
    }
  }
}
</script>
